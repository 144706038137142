<template>
  <div class="page-task-manager">
    <PageSelector
      :pages="pages"
      :selected-page="selectedPage"
      @page-selected="handlePageSelection"
      @reFetch="fetchPages"
    />

    <TasksList
      v-if="selectedPage"
      :tasks="filteredTasks"
      :page-name="selectedPageName"
      @edit-task="openTaskModal"
      @add-task="openTaskModal"
      @reFetch="fetchTasks"
    />

    <TaskModal
      v-model="showTaskModal"
      :edit-task="taskForm"
      :selected-page="selectedPage"
      @reFetch="fetchTasks"
      @close="showTaskModal = false; taskForm = null"
    />
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import PageSelector from './components/PageSelector.vue'
import TasksList from './components/TasksList.vue'
import TaskModal from './components/TaskModal.vue'

export default {
  name: 'PageTaskManager',

  components: {
    PageSelector,
    TasksList,
    TaskModal,
  },

  data() {
    return {
      selectedPage: null,
      pages: [],
      tasks: [],
      showTaskModal: false,
      taskForm: null,
      editingTask: false,
      isProcessingTask: false,
    }
  },

  computed: {
    filteredTasks() {
      return this.tasks.filter(task => task.pageId === this.selectedPage)
    },
    selectedPageName() {
      const page = this.pages.find(p => p.value === this.selectedPage)
      return page ? page.text : ''
    },
  },

  async created() {
    this.fetchPages()
  },

  methods: {
    async fetchPages() {
      try {
        const { data } = await useJwt.getControlPages()
        this.pages = data.map(page => ({
          value: page.id,
          text: page.name,
          key: page.key,
        }))
      } catch (error) {
        console.error('Error fetching pages:', error)
      }
    },

    async handlePageSelection(pageId) {
      this.selectedPage = pageId
      await this.fetchTasks()
    },

    async fetchTasks() {
      if (!this.selectedPage) return
      try {
        const { data } = await useJwt.getControlPageTasks(this.selectedPage)
        this.tasks = data.map(task => ({
          id: task.id,
          pageId: task.page_id,
          name: task.name,
          key: task.key,
          description: task.description,
          editor_info: task.editor_info,
          variables: this.JSON_PARSE(task.variables),
        }))
      } catch (error) {
        console.error('Error fetching tasks:', error)
      }
    },

    handlePageCreation(newPage) {
      this.pages.push(newPage)
      this.selectedPage = newPage.value
    },

    openTaskModal(task = null) {
      if (task) {
        this.editingTask = true
        this.taskForm = { ...task }
      } else {
        this.editingTask = false
        this.resetTaskForm()
      }
      this.showTaskModal = true
    },

    resetTaskForm() {
      this.taskForm = {
        id: null,
        pageId: this.selectedPage,
        name: '',
        key: '',
        description: '',
        editor_info: '',
        variables: [],
      }
      this.editingTask = false
    },
  },
}
</script>

<style scoped>
  @media (max-width: 768px) {
    .page-task-manager {
      padding: 0.5rem;
      max-width: 100%;
    }
  }
</style>
