<template>
  <b-modal
    v-model="show"
    :title="!!taskIdToEdit ? 'Edit Control' : 'Create Control Task'"
    size="lg"
    @hidden="$emit('reset')"
  >
    <b-form @submit.prevent="handleSubmit">
      <b-form-group
        label="Control Name"
        class="mb-2"
      >
        <b-form-input
          v-model="formData.name"
          required
          placeholder="Enter control name"
        />
      </b-form-group>

      <b-form-group
        label="Control Slug"
        class="mb-2"
      >
        <b-form-input
          v-model="formData.key"
          required
          placeholder="Enter control slug"
        />
      </b-form-group>

      <b-form-group
        label="Control Description"
        class="mb-2"
      >
        <b-form-textarea
          v-model="formData.description"
          placeholder="Enter Control description"
          rows="3"
        />
      </b-form-group>

      <b-form-group
        label="Editor Info"
        class="mb-3"
      >
        <VariableEditor v-model="formData.editor_info"
                        :preloaded-variables="formData.variables"
                        @variableChange="v => formData.variables = v"
        />
      </b-form-group>
    </b-form>

    <template #modal-footer>
      <b-button
        variant="secondary"
        @click="show = false"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="isProcessing"
        @click="handleSubmit"
      >
        {{ !!taskIdToEdit ? 'Update' : 'Create' }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import VariableEditor from './VariableEditor.vue'

export default {
  name: 'TaskModal',

  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    VariableEditor,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    editTask: {
      type: Object,
      required: true,
    },
    selectedPage: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      formData: {
        pageId: '',
        name: '',
        key: '',
        description: '',
        editor_info: '',
        variables: [],
        ...this.editTask,
      },
      isProcessing: false,
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    taskIdToEdit() {
      return this.formData?.id || null
    },
  },

  watch: {
    editTask: {
      handler(newVal) {
        this.formData = {
          pageId: '',
          name: '',
          key: '',
          description: '',
          editor_info: '',
          variables: [],
          ...newVal,
        }
      },
      deep: true,
    },
  },

  methods: {
    handleSubmit() {
      if (!this.formData.name || !this.formData.key) {
        this.$bvToast.toast('Please fill in all required fields', {
          title: 'Validation Error',
          variant: 'danger',
        })
        return
      }

      const form = { ...this.formData, page_id: this.selectedPage }
      this.isProcessing = true
      if (this.taskIdToEdit) {
        useJwt.editControlPageTasks(this.taskIdToEdit, form).then(response => {
          this.showSuccessMessage(response)
          this.$emit('close')
          this.$emit('reFetch')
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isProcessing = false
        })
      } else {
        useJwt.createControlPageTasks(form).then(response => {
          this.showSuccessMessage(response)
          this.$emit('close')
          this.$emit('reFetch')
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isProcessing = false
        })
      }
    },
  },
}
</script>
