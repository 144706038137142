<template>
  <b-collapse v-model="value">
    <b-card>
      <h4 class="mb-1">
        Create New Report
      </h4>
      <b-form @submit.prevent="createPage">
        <b-form-group
          label="Report Name"
          class="mb-2"
        >
          <b-form-input
            v-model="formData.name"
            required
            placeholder="Enter report name"
          />
        </b-form-group>

        <b-form-group
          label="Report slug"
          class="mb-3"
        >
          <b-form-input
            v-model="formData.key"
            required
            placeholder="Enter report slug"
          />
        </b-form-group>

        <div class="d-flex justify-content-start">
          <b-button
            type="submit"
            variant="primary"
            class="mr-2"
            :disabled="isProcessing"
          >
            Create
          </b-button>
          <b-button
            type="button"
            variant="secondary"
            @click="cancel"
          >
            Cancel
          </b-button>
        </div>
      </b-form>
    </b-card>
  </b-collapse>
</template>

<script>
import {
  BCollapse, BCard, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'NewPageForm',

  components: {
    BCollapse,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      isProcessing: false,
      formData: {
        name: '',
        key: '',
      },
    }
  },

  methods: {
    createPage() {
      if (!this.formData.name || !this.formData.key) {
        this.$bvToast.toast('Please fill in all page details', {
          title: 'Validation Error',
          variant: 'danger',
        })
        return
      }

      const newPage = {
        name: this.formData.name,
        key: this.formData.key.toUpperCase(),
      }
      this.isProcessing = true
      useJwt.createControlPage(newPage).then(response => {
        this.showSuccessMessage(response)
        this.resetForm()
        this.$emit('page-created')
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },

    cancel() {
      this.resetForm()
      this.$emit('input', false)
    },

    resetForm() {
      this.formData = {
        name: '',
        key: '',
      }
    },
  },
}
</script>
