<template>
  <div>
    <!-- Text Input Section -->
    <b-form-group label="Enter text with variables (e.g. Hello {name}, your age is {age})">
      <b-form-textarea
        v-model="templateText"
        placeholder="Enter text with {variables}"
        @change="extractVariables"
      />
    </b-form-group>

    <!-- Variables Table -->
    <b-table
      v-if="variables.length > 0"
      :items="variables"
      :fields="fields"
      striped
      hover
      class="mt-3"
    >
      <template #cell(value)="data">
        <BFormInput
          v-model="data.item.value"
          placeholder="Enter value"
          size="sm"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BTable,
} from 'bootstrap-vue'

export default {
  name: 'VariableInput',
  // Register components
  components: {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BTable,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    preloadedVariables: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      variables: [],
      fields: [
        {
          key: 'variable',
          label: 'Variable',
          sortable: true,
        },
        {
          key: 'value',
          label: 'Value',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    templateText: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    preloadedVariables: {
      handler(value) {
        this.variables = value
      },
      deep: true,
      immediate: true,
    },
    variables: {
      handler(value) {
        this.$emit('variableChange', value)
      },
      deep: true,
    },
  },
  methods: {
    extractVariables() {
      const regex = /{([^}]+)}/g
      const matches = [...this.templateText.matchAll(regex)]

      // Create a map of existing variables with their values
      const existingVariablesMap = new Map(
        this.variables.map(item => [item.variable, item.value]),
      )

      const newVariables = matches.map(match => ({
        variable: match[1],
        // Use existing value if available, otherwise empty string
        value: existingVariablesMap.get(match[1]) || '',
      }))

      this.variables = Array.from(
        new Map(newVariables.map(item => [item.variable, item])).values(),
      )
    },
  },
}
</script>

  <style scoped>
  .table {
    max-width: 800px;
  }
  </style>
