<template>
  <b-card class="mb-3 tasks-list-card">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h3 class="mb-0">
        Control for {{ pageName }}
      </h3>
      <b-button
        variant="primary"
        @click="$emit('add-task')"
      >
        Add New Control
      </b-button>
    </div>
    <b-table
      :items="tasks"
      :fields="fields"
      striped
      hover
      small
      responsive
    >
      <template #cell(actions)="row">
        <b-button-group>
          <b-button
            size="sm"
            variant="outline-info"
            class="icon-btn"
            @click="$emit('edit-task', row.item)"
          >
            <feather-icon icon="EditIcon"
                          size="16"
            />
          </b-button>
          <b-button
            size="sm"
            variant="outline-danger"
            class="icon-btn"
            @click="taskIdToDelete = row.item.id"
          >
            <feather-icon icon="TrashIcon"
                          size="16"
            />
          </b-button>
        </b-button-group>
      </template>
    </b-table>
    <confirm-delete
      :show="!!taskIdToDelete"
      :is-processing-delete="isDeleting"
      sub-title="Are you sure you want to delete?"
      :on-delete="handleDelete"
      @close="taskIdToDelete = null"
    />
  </b-card>
</template>

<script>
import {
  BCard, BTable, BButton, BButtonGroup,
} from 'bootstrap-vue'
import ConfirmDelete from '@/views/common/components/DeleteModal.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'TasksList',

  components: {
    BCard,
    BTable,
    BButton,
    BButtonGroup,
    ConfirmDelete,
  },

  props: {
    tasks: {
      type: Array,
      required: true,
    },
    pageName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      fields: [
        { key: 'name', label: 'Name' },
        { key: 'key', label: 'Key' },
        { key: 'description', label: 'Description' },
        { key: 'actions', label: 'Actions' },
      ],
      taskIdToDelete: null,
      isDeleting: false,
    }
  },

  methods: {
    handleDelete() {
      this.isDeleting = true
      useJwt.deleteControlPageTasks(this.taskIdToDelete).then(response => {
        this.showSuccessMessage(response)
        this.$emit('reFetch')
        this.taskIdToDelete = null
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isDeleting = false
      })
    },
  },
}
</script>

  <style scoped>
  .tasks-list-card {
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border-radius: 4px;
  }

  .btn-group {
    display: flex;
    gap: 0.25rem;
  }
  </style>
