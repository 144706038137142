<template>
  <b-card class="mb-3 page-selection-card">
    <h3 class="mb-3">
      Report Selection
    </h3>
    <div class="d-flex align-items-end gap-2">
      <b-form-group label="Select Report"
                    class="mb-0 flex-grow-1 mr-2"
      >
        <b-form-select :value="selectedPage"
                       :options="pages"
                       @input="$emit('page-selected', $event)"
        >
          <template #first>
            <b-form-select-option :value="null">
              Please select a Report
            </b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>
      <b-button variant="outline-primary"
                @click="showNewPageForm = !showNewPageForm"
      >
        {{ showNewPageForm ? 'Hide' : 'Create New Report' }}
      </b-button>
    </div>
    <NewPageForm
      v-model="showNewPageForm"
      @page-created="$emit('reFetch')"
    />

  </b-card>
</template>

<script>
import {
  BCard, BFormGroup, BFormSelect, BFormSelectOption, BButton,
} from 'bootstrap-vue'
import NewPageForm from './NewPageForm.vue'

export default {
  name: 'PageSelector',

  components: {
    BCard,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BButton,
    NewPageForm,
  },

  props: {
    pages: {
      type: Array,
      required: true,
    },
    selectedPage: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      showNewPageForm: false,
    }
  },

  methods: {
    handlePageCreated(newPage) {
      this.$emit('page-created', newPage)
      this.showNewPageForm = false
    },
  },
}
</script>

  <style scoped>
  .page-selection-card {
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border-radius: 4px;
  }
  </style>
